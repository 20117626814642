body {
  font-family: "Poppins" !important;
}

:root {
  --aspect-ratio: 0.5625;
}

.aspectRatio {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.main {
  width: 100%;
  --size: calc(var(--aspect-ratio) * 89vw);
  height: calc(var(--size) - 16px);
  /* background-color: #0c4da9;
  padding: 0.5%; */
}

.leftCard,
.rightCard {
  height: calc(var(--aspect-ratio) * 81.57vw);
  /* height: 94vh; */
}

/* .leftCard {
  margin-right: 0.5%;
  border-radius: 5px;
}

.rightCard {
  margin-left: 0.5%;
  border-radius: 5px;
} */

/* .login-card {
  max-width: 350px;
  margin-left: 3vh !important;
  text-align: left !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 20px !important;
  border: 1px solid #ebeded !important;
} */

.topay-aspect-ratio {
  max-width: 400px !important;
}

/* .add-payment-btn {
  height: 8vh !important;
  width: 100% !important;
  background: #20b883 !important;
  color: #ffffff !important;
  border-radius: 6px !important;
  opacity: 1;
  font-weight: bold !important;
  font-size: 1.5vw;
} */

/* Print Customer and Order Details Modal */

.customerPrintDetails {
  padding: 10px;
  margin: 3px;
  font-family: "Times New Roman";
  font-weight: 600;
  border: 1px solid #000000;
  border-style: dashed;
}

.printPaymentBtn,
.cancelPaymentBtn {
  color: #ffffff;
  width: 30%;
  border-radius: 5px;
  background: #607d8b;
}

.cancelPaymentBtn:hover,
.printPaymentBtn:hover,
.cancelPaymentBtn:focus,
.printPaymentBtn:focus {
  border-radius: 5px;
  background: #607d8b;
  border-color: #607d8b;
  color: #ffffff;
}

.paymtName,
.paymtAmt {
  font-size: 1vw;
}

/* .delete-icon {
  width: 12px !important;
  margin-top: -3px !important;
} */

/* .paymt-card {
  padding: 15px;
  border: 1px solid #ebeded !important;
  border-radius: 6px !important;
  opacity: 1 !important;
} */

.text-align-center {
  text-align: center !important;
}

/* .topay-input {
  box-shadow: 0px 0.5px 4px #00000024 !important;
  border: 1px solid #ebeded !important;
  border-radius: 5px !important;
  height: 8vh !important;
  width: 100%;
  font-size: 1.8vw;
  font-weight: 600;
  text-align: center;
} */

.toPayText {
  font-size: 1.5vw;
  font-weight: 600;
  color: #000000;
}

.toPayTextValue {
  float: right;
  font-weight: 600;
  color: #000000;
  font-size: 1.5vw;
}

.para-margin {
  margin-bottom: 8px !important;
}

.amt-dial-btn {
  width: 100%;
  height: 7vh;
  border-radius: 10px;
  background: #ebeded;
  box-shadow: 0px 3px 6px #0000004c;
  border: 1px solid #ebeded;
  font-size: 1.5vw;
  font-weight: 600;
}

.amt-dial-btn-back {
  margin-top: -5px;
}

/* .topay-entr-amt {
  margin-bottom: 0;
  color: #030d1c;
  font-size: 1.5vw;
  font-weight: 600;
} */

.right-float {
  float: right !important;
}

/* .wfifty {
  width: 50px !important;
} */

/* .productImg {
  width: 80px !important;
} */

.productImgWidth {
  width: 120px !important;
}

.productImgHeight {
  height: 120px !important;
}

.hfifty {
  height: 50px !important;
}

/* .no-border-card {
  border: none !important;
} */

/* .selectedPaymentType {
  padding: 15px;
  border: 1px solid #ebeded !important;
  border-radius: 6px !important;
  background: #607d8b;
} */

/* .selectedPaymentTypeName {
  font-size: 1.2vw;
  font-weight: 600;
  color: #ffffff;
  float: right;
}

.paymt-name {
  font-size: 1.2vw;
  font-weight: 600;
  color: #374957;
  float: right;
} */

.rupees-tax {
  width: 12px !important;
  margin-top: -3px !important;
}

/* .prod-name {
  font-size: 0.9vw !important;
  color: #030d1c !important;
  font-weight: normal !important;
} */

/* .loginLogo {
  width: 60px;
  height: 50px;
  margin-top: -70px;
} */

/* .login-label {
  text-align: left;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
  font-size: 1.12em;
}

.login-input {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  border: none !important;
} */

/* .btnCommon {
  background-color: #0c4da9 !important;
  color: #ffffff !important;
  width: 100%;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 30px !important;
  opacity: 1;
} */

/* .copyright-main-div {
  position: absolute !important;
  bottom: 10px !important;
}

.copyright {
  text-align: center;
  letter-spacing: 0px;
  color: #070000;
  font-size: 1em !important;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
} */

/* pos page css starts*/
/* .menu-icon {
  width: 23% !important;
} */

/* .pos-head-label {
  font-weight: 600 !important;
  font-size: 1.1vw !important;
  letter-spacing: 0.36px !important;
  color: #ffffff !important;
  opacity: 1 !important;
} */

/* .time-fmt {
  font-weight: 600 !important;
  font-size: 1.1vw !important;
  color: #ffffff !important;
  margin-left: 2% !important;
  letter-spacing: 0.36px !important;
  opacity: 1 !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.time {
  color: #ffffff !important;
  text-align: right;
  font-weight: 600 !important;
  font-size: 1.1vw !important;
  letter-spacing: 0.36px;
  opacity: 1;
}

.pos-logo-icon {
  width: 13% !important;
} */

/* .cardShadow {
  box-shadow: 0px 3px 6px #00000029 !important;
}
.card-radius {
  border-radius: 5px !important;
} */

/* .labelA {
  color: #0c4da9 !important;
  font-size: 1vw !important;
  font-weight: 600 !important;
}
.labelB {
  color: #000000 !important;
  font-size: 1vw !important;
  font-weight: 600 !important;
} */

.downIcon {
  width: calc(var(--aspect-ratio) * 3vw);
  height: calc(var(--aspect-ratio) * 3vw);
  /* width: 22px !important; */
  /* float: right !important; */
}

.paid-icon {
  width: 8px !important;
  margin-top: -3px !important;
}

/* .amt-text-align {
  text-align: right;
} */

/* .tax-dis-lbl {
  padding: 0 15px;
  text-align: left !important;
  font-size: 1vw !important;
  font-weight: 600 !important;
  color: #000000 !important;
  opacity: 1 !important;
} */

.table-stl {
  /* width: 100%; */
  height: calc(var(--aspect-ratio) * 66.5vw);
  /* overflow: auto !important; */
  /* border: none !important; */
  /* height: 76vh; */
}

.offerName {
  font-family: Poppins;
  font-size: 0.8vw !important;
  color: #00000080;
}

.tableFooterRow {
  padding: 7px;
  background-color: "";
}

.totalBtn {
  height: calc(var(--aspect-ratio) * 6.2vw);
  /* width: 100%;
  border-radius: 7px;
  background: #20b883; */
}

.totalBtn:hover,
.totalBtn:active,
.totalBtn:focus {
  width: 100%;
  border-radius: 7px;
  background: #20b883 !important;
}

.totalLabel {
  color: #ffffff;
  font-size: 1.5vw;
  font-weight: bold;
}

.productSpecImg {
  width: calc(var(--aspect-ratio) * 3.5vw);
  height: calc(var(--aspect-ratio) * 3.5vw);
}

.productSpecImgDec {
  width: calc(var(--aspect-ratio) * 3.5vw);
}

/* .addProductsCard,
.searchProductsCard,
.quantityUpdateCard {
  padding: 8px;
  width: 100%;
  background: #f9f9f9;
} */

.addProductsCard,
.quantityUpdateCard {
  /* height: calc(var(--aspect-ratio) * 81.25vw); */
  height: calc(var(--aspect-ratio) * 68.5vw);
  /* height: calc(var(--size) - 15px); */
  /* height: 78vh; */
}

.productScroll {
  height: calc(var(--aspect-ratio) * 69vw) !important;
  /* height: 79vh !important; */
}

/* .prodCard {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #ebeded;
} */

.searchIcon {
  width: 18px !important;
}

.dialer-btn-image {
  height: 95% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.dialer-btn {
  height: calc(var(--aspect-ratio) * 10.6vw);
  width: calc(var(--aspect-ratio) * 12vw);
  /* background: #ebeded;
  box-shadow: 0px 3px 6px #0000004c;
  border-radius: 15px;
  border: 1px solid #ebeded;
  font-size: 1.7vw;
  font-weight: 600; */
}

.addBySKUbtn,
.addByUPCbtn {
  height: calc(var(--aspect-ratio) * 10.6vw);
  width: calc(var(--aspect-ratio) * 12vw);
  /* box-shadow: 0px 3px 6px #0000004c; */
  /* background: #607d8b;
  color: #ffffff;
  border: 1px solid #607d8b;
  border-radius: 15px;
  font-size: 2vw;
  font-weight: 600; */
}

.productSpecChange {
  /* height: calc(6.1vw - 10px); */
  height: calc(var(--aspect-ratio) * 8vw);
  /* width: calc(6vw - 10px); */
  /* width: 100%;
  box-shadow: 0px 3px 6px #0000004c;
  background: #607d8b;
  color: #ffffff;
  border: 1px solid #607d8b;
  border-radius: 15px; */
}

/* .productSpecChange {
  height: calc(5.7vw - 10px);
  width: calc(6vw - 10px);
} */

.infoButton {
  font-size: 3.2vw;
}

/* .addprodback {
  width: 30px;
  height: 20px;
} */

.specCol {
  /* margin-bottom: 11px; */
  margin-left: 8px;
}

.specCol-dash {
  /* margin-bottom: 0px; */
  margin-left: 8px;
}

button {
  outline: none;
}

.dialer-btn-image2 {
  height: 92% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.dialer-btn-image3 {
  height: 92% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.aspect-ratio-for-update-qty {
  max-width: 400px !important;
  height: auto !important;
  min-height: 40px !important;
}

.aspect-ratio-for-firstleft-icons {
  max-width: 400px !important;
  height: auto !important;
  min-height: 40px !important;
}

/*dashboard css starts*/
/* .workType {
  padding: 24px;
} */

/* .imgWorkType {
  height: 37px;
  width: 33px;
  margin-bottom: 15px;
} */

/* .cardWorkType {
  height: 25vh;
  padding: 30px;
  box-shadow: 1px 2px 7px -3px !important;
} */

.logoIcon {
  width: 96px !important;
  height: 77px !important;
}

/* .sync {
  width: 40px !important;
  height: 40px !important;
}

.logout {
  width: 40px !important;
  height: 40px !important;
} */

.dbbackup {
  width: 40px !important;
  height: 40px !important;
}

/* .dashboard-label {
  font-size: 1.3vw !important;
  color: #000000 !important;
  text-align: center;
} */

/* .dashboard-first-row {
  margin: 3vh 0vh 0vh 3vh !important;
} */

/* .dashboard-info {
  text-align: left !important;
  font-size: 1.2vw !important;
  color: #ffffff !important;
} */

/* .dashboard-greet {
  float: right !important;
  font-size: 1.2vw !important;
  color: #ffffff !important;
} */

.col-text-align {
  text-align: center !important;
}

/* .dashboard-sync-logout-mainrow {
  margin: 15vh 0vh 0vh 3vh !important;
  float: right;
  padding-left: 1.7em;
  padding-right: 1.7em;
} */

/*dashboard css ends */

/* sync page */
/* .rowGap {
  margin-top: 10px;
} */

/* .logoIcon {
  width: 40px;
  height: 32px;
} */

/* .logoIconDashboard {
  width: 60px;
  height: 60px;
} */

/* .mainDivStyl {
  padding: 10px;
} */

/* .iconStyl {
  font-size: 30px;
  color: green !important;
} */
/*ends*/

/* Customer Selection Radio Button */
/* .radio-lbl-cus,
.radio-lbl-ph {
  font-weight: 600;
}

.radio-lbl-ph {
  padding-left: 22px;
} */

/* .customer-search-input {
  height: 7vh;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
} */

.customer-search-input-suffix {
  padding: 20px;
  margin-right: -12px;
  border-radius: 5px;
  border-left: 1px solid #cfd9e0;
}

.customer-search-input-suffix:hover {
  border: 1px solid #1890ff;
}

.custSearchIcon {
  font-size: 1vw;
  color: #37474f;
}

/* .customerSearchList {
  max-height: 60vh;
  min-height: 55vh;
} */

/* .customerDetailsCard {
  background: #0c4da90d;
  margin-bottom: 10px;
} */

/* .customerName {
  margin-bottom: 0;
  color: #000000;
  font-size: 0.8vw;
  font-weight: 600;
} */

/* .customerStatus {
  margin-bottom: 0;
  color: #0c4da9;
  font-size: 0.8vw;
  font-weight: 600;
} */

/* .noResultsText {
  font-size: 1vw;
  color: #b9b9b9;
} */

/* .addNewCustomer {
  font-size: 1vw;
  color: #0c4da9;
} */

.addCustArrow {
  color: #000000;
  font-size: 1.5vw;
}

.addCustTitle {
  margin-bottom: 0px;
  font-size: 1.5vw;
  padding-left: 20px;
}

/* .cutomerDetailsField {
  font-size: 1em !important;
  color: #000000;
} */

.customerDetailsSec {
  padding-left: 30px;
}

/* .cutomerDetailsInput {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ebeded;
} */

.cutomerDobInput {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ebeded;
}

/* .customerDetailsBtns {
  justify-content: center;
  padding-top: 20px;
} */

/* .customerDetailsCancelBtn,
.customerDetailsSubmitBtnBtn {
  color: #ffffff;
  border-radius: 5px;
  background: #607d8b;
} */

.customerDetailsCancelBtn:hover,
.customerDetailsCancelBtn:focus {
  background: #707070;
  border-color: #607d8b;
  border-radius: 5px;
  color: #ffffff;
}

.customerDetailsSubmitBtnBtn:hover,
.customerDetailsSubmitBtnBtn:focus {
  background: #0c4da9;
  border-color: #0c4da9;
  border-radius: 5px;
  color: #ffffff;
}

/* .customerDetailsCancelBtn {
  background: #707070;
} */

/* .customerDetailsSubmitBtnBtn {
  margin-left: 30px;
  background: #0c4da9;
} */

/* .formItem {
  margin-bottom: 10px;
} */

/* Ends */

/* Returns Modal CSS */
.returnsButton {
  width: 15%;
  height: 6vh;
  font-size: 18px;
  margin-top: 50px;
  color: #ffffff !important;
  border-radius: 5px;
  background: #607d8b !important;
  float: right;
}

.returnsButton:hover {
  color: #ffffff !important;
  border-radius: 5px;
  background: #607d8b !important;
  border-color: #607d8b;
}

.returnProductsTable {
  width: 100%;
  border: 1px solid #ebeded;
  border-collapse: collapse;
  border-radius: 5px 5px 0px 0px !important;
}

.returnProductsTableThead {
  background-color: #ebeded;
}

.returnProductsTableTd,
.returnProductsTableTh {
  text-align: center;
  padding: 15px;
}

.returnProductsTableTd,
.returnProductsTableTdName {
  font-size: 12px;
  font-weight: 600;
}

.returnProductsTableTdName,
.returnProductsTableThName {
  text-align: left;
}

tr:hover {
  background-color: #ebeded;
}

.returnsQtyBtns {
  color: #607d8b;
}

.returnQunatity {
  display: flex;
  justify-content: center;
}

.returnsQtyInc,
.returnsQtyDec {
  color: #607d8b;
  padding-top: 8px;
  font-size: 15px;
}

.returnsQtyInc {
  padding-left: 10px;
}

.returnsQtyDec {
  padding-right: 10px;
}

.returnsQtyInput {
  border-radius: 5px;
  border: 1px solid #ebeded;
  text-align: center;
}

.returnsQtyFormitem {
  width: 15%;
  margin-bottom: 0;
}

/* Ends */

/*pos page css ends*/

/* pos new dialer pad*/
.wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 2fr);
  grid-gap: 2px;
  padding: 15px 8px 8px;
  height: 61vh;
}

.item2 {
  grid-column: 2/3;
  grid-row: 1/2;
  text-align: center;
}
.item3 {
  grid-column: 3/4;
  grid-row: 1/2;
  text-align: center;
}
.item4 {
  grid-column: 4/5;
  grid-row: 1/2;
  text-align: center;
}

.item5 {
  grid-column: 5/6;
  grid-row: 1/2;
  text-align: center;
}
/*second row*/
.item6 {
  grid-column: 2/3;
  grid-row: 2/2;
  text-align: center;
}
.item7 {
  grid-column: 3/4;
  grid-row: 2/2;
  text-align: center;
}
.item8 {
  grid-column: 4/5;
  grid-row: 2/2;
  text-align: center;
}
.item9 {
  grid-column: 5/6;
  grid-row: 2/2;
  text-align: center;
}
/*second row ends*/

/*third row*/
.item10 {
  grid-column: 2/3;
  grid-row: 3/3;
  text-align: center;
}

.item11 {
  grid-column: 3/4;
  grid-row: 3/3;
  text-align: center;
}
.item12 {
  grid-column: 4/5;
  grid-row: 3/3;
  text-align: center;
}
.item13 {
  grid-column: 5/6;
  grid-row: 3/3;
  text-align: center;
}
/* third row ends*/

/*fourth row starts*/
.item14 {
  grid-column: 2/3;
  grid-row: 4/4;
  text-align: center;
}
.item15 {
  grid-column: 3/4;
  grid-row: 4/4;
  text-align: center;
}
.item16 {
  grid-column: 4/5;
  grid-row: 4/4;
  text-align: center;
}
.item17 {
  grid-column: 5/6;
  grid-row: 4/4;
  text-align: center;
}

.item18 {
  grid-column: 2/3;
  grid-row: 5/5;
  text-align: center;
  bottom: 0;
}

/*fourth row ends*/

/* pos new dialer pad ends*/

/* .add-product-save,
.add-product-pay {
  background: #607d8b !important;
  color: #ffffff !important;
  font-size: 1.5vw !important;
  font-weight: 600 !important;
  border-radius: 7px;
}

.add-product-pay {
  float: right !important;
  background: #ff8c00 !important;
} */

.add-product-save,
.add-product-pay {
  height: calc(var(--aspect-ratio) * 6.2vw);
  width: 100%;
}

/* .addProductInput {
  width: 97%;
  padding: 3%;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 1.8vw;
  font-weight: 600;
  text-align: center;
} */

/* START - custom CSS While using functional components */
/* .sectionStyle {
  width: 100% !important;
  height: 100% !important;
  padding-top: 27vh;
  background-image: url("../assets/images/backgroundImage.png");
  background-repeat: no-repeat;
  background-size: cover;
} */

/* .spinLoader {
  font-size: 24;
} */

.ant-table-selection-col,
.ant-table-selection-column {
  display: none;
}

/* END - custom CSS  While using functional components */

/* Order History Modal CSS*/
.backArrowOrderHistory,
.productSearchIcon {
  font-size: 1.8vw;
}

.productSearchIcon {
  font-size: 1.6vw;
}

.backArrowParkedOrderHistory {
  padding-top: 5px;
  font-size: 1.2vw;
}

.orderHistorySearchIcon {
  font-size: 1.5vw;
  color: #37474f;
}

/* .orderHistoryModalTitle {
  text-align: left;
  font-size: 1.5vw;
  font-weight: 600;
  color: #0c4da9;
} */

/* .parkedBillsHistoryModalTitle {
  text-align: left;
  font-size: 1.3vw;
  font-weight: 600;
  color: #0c4da9;
} */

/* .orderHistorySearchInput {
  height: 7vh;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
} */

.orderHistorySearchInputSuffix {
  padding: 20px;
  /* margin-top: -8px;
  margin-bottom: -8px; */
  margin-right: -12px;
  border-radius: 5px;
  /* border-left: 1px solid #CFD9E0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; */
  border-left: 1px solid #cfd9e0;
}

.orderHistorySearchInputSuffix:hover {
  border: 1px solid #1890ff;
}

/* .orderHistoryAttribute {
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
} */

.attributes {
  margin-bottom: 0;
  font-size: 16px;
  color: #a6adb4;
}

.attributesDropdown {
  float: right;
  font-size: 24px;
}

.orderHistoryCard,
.orderHistoryTabs,
.oderHistoryDetailsCard {
  width: 100%;
}

.orderHistoryCard,
.orderHistoryScroll {
  min-height: calc(var(--aspect-ratio) * 42vw);
}

/* .oderHistoryDetailsCard {
  background: #0c4da90d;
  margin-bottom: 10px;
} */

/* .orderDate {
  color: #7d7d7d;
  font-size: 0.9vw;
}

.orderCustomerName {
  float: right;
  color: #7d7d7d;
  font-size: 0.9vw;
} */

/* .dropdownCircleIcon {
  float: right;
} */

/* .orderNum {
  margin-bottom: 0;
  color: #000000;
  font-size: 0.9vw;
  font-weight: 600;
}

.orderStatus {
  margin-bottom: 0;
  float: right;
  color: #0c4da9;
  font-size: 0.9vw;
  font-weight: 600;
} */

/* .orderDetailsTable {
  width: 100%;
  margin-top: 10px;
}

.orderDetailsTableHeader {
  font-size: 0.9vw;
  color: #607d8b;
}

.orderDetailsTableTh {
  text-align: center;
}

.orderDetailsData {
  text-align: center;
  font-size: 0.8vw;
  color: #000000;
}

.orderDetailsDesc {
  text-align: left;
}

.orderDetailsTaxes,
.orderDetailsTotal {
  width: 100%;
  font-size: 0.9vw;
  font-weight: 600;
} */

/* .orderDetailsButtons {
  width: 100%;
} */

.rePrintBtn:hover,
.returnBtn:hover {
  background: #607d8b;
  color: white;
  border-radius: 5px;
  font-size: 1vw;
  font-weight: 600;
}

/* .returnBtn {
  float: right;
} */

.parkedModalContent {
  font-size: 1.3vw;
  font-weight: normal;
  color: #0c4da9;
  margin-bottom: 0;
  padding-top: 3px;
}

.parkedModalIcon {
  float: right;
  padding-left: 30px;
  padding-right: 20px;
  color: #20b883;
  font-size: 1.62vw;
}

.syncnowBtn {
  left: 40%;
  margin-bottom: 8px;
}

/* Ends */

/* Product Category Filters CSS */

/* .productsSearchCol {
  padding-right: 8px;
} */

/* .categoryFiltersCol {
  border: 1px solid #ebeded;
  border-right: none;
  border-bottom: none;
  margin-top: -10px;
} */

/* .productsFilterBtn {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #cfd9e0;
} */

.productsFilterBtn,
.productsFilterBtn:hover,
.productsFilterBtn:focus {
  border: 1px solid #cfd9e0;
}

/* .productSearchSuffix {
  font-size: 35px;
  color: #bac8d3;
} */

/* .filterIcon {
  height: 25px;
  width: 25px;
} */

/* Ends */

/* Cash/Carry Modal CSS */
.cashCarryBtn {
  width: 70%;
  float: right;
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
}

.deliveryBtn {
  width: 70%;
  margin-left: 20px;
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
}

.cashCarryBtn:hover,
.deliveryBtn:hover,
.cashCarryBtn:focus,
.deliveryBtn:focus {
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
  color: #000000;
}

/* Ends */

/* Print Bill Popup css */
.printBillText {
  text-align: center;
  color: #0c4da9;
  font-size: 18px;
}

.yesBtn,
.noBtn {
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
  width: 50%;
}

.yesBtn:hover,
.noBtn:hover,
.yesBtn:focus,
.noBtn:focus {
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
  color: #000000;
}

.yesBtn {
  float: right;
}

.noBtn {
  margin-left: 20px;
}
/* Ends */

@media print {
  body * {
    margin: 0;
    padding-left: 5px;
    visibility: hidden !important;
    zoom: 100% !important;
    box-shadow: none !important;
  }

  .section-not-to-print,
  .section-not-to-print * {
    display: none !important;
  }

  .section-to-print,
  .section-to-print * {
    visibility: visible !important;
    width: 100% !important;
  }

  .section-to-print {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
  }
}

.btn-card {
  height: 89vh;
  margin-top: 10px !important;
}

.btn-till {
  background-color: #314eaf !important;
  color: #ffffff !important;
  width: 60%;
  margin-top: 2%;
  font-weight: bold;
  font-size: large;
}
.btn-container {
  padding: 15%;
  text-align: center;
}
.logo-card {
  height: 7vh;
  padding: 4px !important;
}

/* .main-container {
  background-color: #f8fbff;
  height: 100vh;
  padding: 10px;
} */

/* .main-card-container {
  margin-top: 10px !important;
  background-color: #f8fbff !important;
} */

.main-card-first-open-till {
  height: 89vh;
}

/* .main-card-first {
  height: 87.5vh;
} */

.card-notes {
  margin-top: 10px !important;
}

/* .main-card-second-open-till {
  height: 81.2vh;
  margin-top: 1px !important;
} */

.btn-container {
  padding: 15%;
  text-align: center;
}
/* .card-shadow {
  box-shadow: 1px 2px 4px -3px !important;
  border-radius: 5px !important;
} */
.logo-card {
  height: 7vh;
  padding: 4px !important;
}

/* .logo-icon {
  width: 40px !important;
  height: 32px !important;
} */
/* .currency {
  background-color: #dfeafd !important;
  width: 100px !important;
  font-weight: 700px !important;
  text-align: right !important;
  border: none !important;
}

.currency-count {
  font-weight: bold !important;
  text-align: center !important;
  width: 40% !important;
  font-size: 18px !important;
}

.total-input {
  width: 100 px !important;
  font-weight: 700px !important;
  text-align: right !important;
  border: none !important;
} */
/* .col-align {
  text-align: right !important;
}

.col-amonut {
  text-align: right !important;
} */

.formItem {
  margin-bottom: 10px !important;
  line-height: 10px !important;
}

/* .btn-confirm {
  width: 49.5%;
  margin-left: 1%;
  background-color: #314eaf !important;
  color: #ffffff !important;
  font-size: 1.2vw;
} */

/* .btn-cancel {
  width: 49.5%;
  background-color: #ffffff !important;
  color: #000000 !important;
  font-size: 1.2vw;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .heading-till {
  margin-bottom: 0;
  padding-top: 0.5%;
  font-size: 16px;
  font-family: Poppins !important;
  font-weight: 600;
} */

.tillScroll {
  height: calc(var(--aspect-ratio) * 70vw) !important;
}

.closeTillScroll {
  height: calc(var(--aspect-ratio) * 70vw) !important;
}

.transactionAmtInput,
.transactionAmtInput:focus {
  width: 70%;
  padding: 10px;
  background: #ebeded;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
}

/* fdfdfdf*/

/* Product Info Modal */

/* .prod-head {
  font-size: 18px;
  font-weight: 600;
} */

.prod-weight,
.prod-pack,
.prod-offer,
.prod-descp-price-mrp {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}

.prod-descp-price-mrp {
  text-decoration: line-through;
}

/* .prod-descp-price {
  color: #535665;
} */

.prod-pack {
  color: #707070;
}

.prod-offer {
  color: #ff0000;
}

/* .prod-qty {
  display: flex;
} */

.prod-qty-dec,
.prod-qty-inc {
  padding: 8px;
  font-size: 18px;
  color: #607d8b;
  border: 0.5px solid #0000004c;
}

.prod-qty-dec {
  border-right: none;
}

.prod-qty-inc {
  border-left: none;
}

/* .prod-qty-input {
  border-radius: 0px;
  padding: 8px;
  border: 1px solid #0000004c;
  text-align: center;
} */

/* .prod-qty-form {
  width: 20%;
  margin-bottom: 0;
} */

.prod-qty-btn:hover,
.prod-qty-btn:focus {
  width: 70%;
  background-color: #0c4da9;
  color: #ffffff;
  border: 1px solid #0c4da9;
  border-radius: 0px;
  float: right;
}

.prod-select {
  padding-top: 20px;
  padding-right: 15px;
  font-size: 24px;
  float: right;
}

.prod-selected-col {
  border: 1px solid #607d8b;
  background: #607d8b;
  border-radius: 0px 2px 2px 0px;
  color: #ffffff;
}

.prod-col {
  padding: 5px 0 5px 10px;
}

.prod-select-div {
  margin-bottom: 10px;
  background: #eef5ff;
  border: 1px solid #d3e5ff;
  border-radius: 5px;
}

.prod-div {
  margin-bottom: 10px;
  border: 1px solid #0000004c;
  border-radius: 5px;
}

/* .aspect-ratio {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
} */

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .addProductsCard,
  .searchProductsCard,
  .quantityUpdateCard {
    padding: 30px;
    padding-top: 10px;
  }

  .addProductsCard,
  .quantityUpdateCard {
    /* height: calc(var(--aspect-ratio) * 78.25vw); */
    height: 82vh;
  }

  .searchProductsCard,
  .quantityUpdateCard {
    padding: 8px;
  }

  .addProductInput {
    padding: 6% !important;
  }

  .filterIcon {
    height: 12px !important;
    width: 12px !important;
  }

  /* .quantity-btn-zero {
    height: 6vh;
  } */

  /* .qty-card-row {
    padding-top: 10px;
  } */

  /* .qty-img {
    width: 25px !important;
  } */

  .qty-btn-back {
    width: 20px;
    height: 15px;
  }

  .qty-btn-close {
    width: 15px;
    height: 15px;
  }

  .delete-icon {
    width: 10px !important;
    margin-top: -6px !important;
  }

  .productImgWidth {
    width: 80px !important;
  }

  .productImgHeight {
    height: 80px !important;
  }

  /* .offersCardList {
    min-height: 34vh;
  } */
}

@media only screen and (max-width: 595px) {
  .sectionStyle {
    width: 100% !important;
    height: 100% !important;
    padding-top: 10vh;
    background-image: url("../assets/images/backgroundImgMobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .login-card {
    max-width: 300px;
    margin-left: 2vh !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 0 !important;
    border: 1px solid #ebeded !important;
  }

  .loginLogo {
    width: 60px;
    height: 50px;
    margin-top: 0;
    margin-left: -5px;
    margin-bottom: 20px;
  }

  .copyright-main-div {
    display: none;
  }

  .cardWorkType {
    height: 18vh;
    padding: 0;
    box-shadow: 1px 2px 7px -3px !important;
  }

  .dashboard-label {
    font-size: 1em !important;
    color: #000000 !important;
  }

  .dashboard-sync-logout-mainrow {
    margin: -22% 0 0 !important;
  }
}

@media only screen and (min-width: 1920px) {
  .logoIconDashboard {
    width: 80px;
    height: 80px;
  }

  .imgWorkType {
    height: 80px;
    width: 70px;
    margin-bottom: 15px;
  }

  .sync {
    width: 60px !important;
    height: 60px !important;
  }

  .logout {
    width: 60px !important;
    height: 60px !important;
  }

  .menu-icon {
    width: 28% !important;
  }

  /* .quantity-btn,
  .quantity-btn-ok,
  .quantity-btn-zero,
  .dialer-btn,
  .addBySKUbtn,
  .addByUPCbtn {
    border-radius: 20px;
  } */

  /* .quantity-btn-zero {
    height: 10vh;
  } */

  /* .qty-card-row {
    padding-top: 30px;
  } */

  /* .qty-img {
    width: 50px !important;
  } */

  .delete-icon {
    width: 15px !important;
    margin-top: 6px !important;
  }

  /* .table-stl {
    height: calc(var(--aspect-ratio) * 70vw);
  } */

  .productSpecChange {
    height: calc(var(--aspect-ratio) * 8.6vw);
    border-radius: 20px;
  }

  /* .addProductsCard,
  .quantityUpdateCard {
    height: calc(var(--aspect-ratio) * 70.5vw);
  } */

  .addProductInput {
    padding: 4%;
  }

  .productScroll {
    height: calc(var(--aspect-ratio) * 70.5vw) !important;
  }

  .productInfoCard {
    height: calc(var(--aspect-ratio) * 17vw) !important;
  }

  .prodQtyCard {
    height: calc(var(--aspect-ratio) * 50.1vw) !important;
  }

  .qty-card {
    height: calc(var(--aspect-ratio) * 10vw) !important;
  }

  .qty-card-row {
    padding-top: 5% !important;
  }

  .quantityUpdateInput {
    padding: 5% !important;
  }

  .quantity-btn-zero {
    height: calc(var(--aspect-ratio) * 7.5vw) !important;
  }
}

/* .prod-total {
  width: 100%;
  position: absolute !important;
  bottom: 0px !important;
} */

/* Quantity Offers, Stock, Sales, Info Pages */

/* .txt-cen {
  text-align: center !important;
} */

.qty-card {
  height: calc(var(--aspect-ratio) * 9.5vw);
  /* width: 100% !important; */
}

/* .qty-card-row {
  padding-top: 3%;
} */

.qty-img {
  width: calc(var(--aspect-ratio) * 4.5vw) !important;
}

/* .no-border-crd {
  border: none !important;
} */

.padding-ten {
  padding: 10 !important;
}

/* .selected {
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #000000;
  box-shadow: 0px 0px 10px #607d8b1a;
  border-radius: 5px;
  color: #374957;
} */

.qty-lbl {
  font-size: 1.5vw !important;
  font-family: Poppins !important;
  font-weight: 600 !important;
  color: #374957 !important;
}

.productInfoCard {
  height: calc(var(--aspect-ratio) * 20vw);
  width: 100% !important;
  /* border-radius: 6px; */
}

.prodInfoImage {
  width: 100%;
  height: 100%;
}

.prodInfoCol {
  padding-left: 10px;
}

/* .prod-name-details {
  font-size: 1vw !important;
  font-weight: 600 !important;
  font-family: Poppins !important;
  letter-spacing: 0.36px;
  color: #030d1c;
}

.prod-price-lbl {
  padding-top: 5px;
  font-size: 0.9vw !important;
  color: #030d1c !important;
  font-weight: 600 !important;
  font-family: Poppins !important;
  letter-spacing: 0.36px;
} */

.prod-desc-lbl {
  /* padding-top: 5px;
  margin-bottom: 0;
  font-size: 0.9vw !important;
  font-family: Poppins !important;
  color: #030d1c !important;
  font-weight: normal !important;
  width: 90%;
  overflow: hidden; */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.prodQtyCard {
  height: calc(var(--aspect-ratio) * 47.6vw);
  box-shadow: rgb(0 0 0 / 16%) 0px 2px 4px;
  border-radius: 6px;
  /* padding: 20px; */
}

.emptyCard {
  height: calc(var(--aspect-ratio) * 47.6vw);
  /* background-color: #fff; */
  /* border-radius: 6px; */
}

/* .quantityUpdateInput {
  padding: 3%;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 1.5vw;
  font-weight: 600;
  text-align: center;
} */

/* .quantity-btn {
  border-radius: 12px;
  background: #ebeded;
  box-shadow: 0px 3px 6px #0000004c;
  border: 1px solid #ebeded;
  font-size: 1.6vw;
  font-weight: 600;
} */

.quantity-btn-zero {
  /* width: 100%; */
  height: calc(var(--aspect-ratio) * 6.5vw);
  /* border-radius: 12px;
  background: #ebeded;
  box-shadow: 0px 3px 6px #0000004c;
  border: 1px solid #ebeded;
  font-size: 1.6vw;
  font-weight: 600; */
}

/* .quantity-btn-ok {
  width: 100%;
  height: 100%;
  background: #20b883;
  border: 1px solid #20b883;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #0000004c;
  font-size: 1.6vw;
  font-weight: 600;
}

.qty-btn-back {
  width: 30px;
  height: 25px;
}

.qty-btn-close {
  width: 25px;
  height: 25px;
} */

.offersCard {
  height: calc(var(--aspect-ratio) * 47.5vw);
  box-shadow: rgb(0 0 0 / 16%) 0px 2px 4px;
  border-radius: 6px;
}

.offersCardList {
  min-height: calc(var(--aspect-ratio) * 40vw);
}

.infoCardList {
  min-height: calc(var(--aspect-ratio) * 40vw);
  overflow-x: hidden !important;
}

.infoTitle {
  margin-bottom: 0;
  font-size: 0.9vw;
  font-family: Poppins;
  letter-spacing: 0.24px;
  color: #607d8b;
}

.infoDesc {
  font-size: 0.9vw;
  font-family: Poppins;
  letter-spacing: 0.24px;
  color: #000000;
}

.ribbon {
  position: relative;
}
.ribbon-1 {
  width: 50px;
  height: 50px;
  background-color: #02c437;
  top: 150px;
  left: -10px;
}

.ribbon-1::before {
  content: "";
  position: relative;
  top: -10px;
  right: 0px;
  border-left: 5px solid #02c437;
  border-right: 5px solid transparent;
  border-top: 5px solid transparent;
  border-bottom: 5px solid #02c437;
}

.ribbon-1::after {
  content: "";
  position: relative;
  top: 0;
  left: -1px;
  border-left: 25px solid #fff;
  border-right: 25px solid transparent;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
}

/* Layout 2 */

.mainRow {
  height: calc(var(--aspect-ratio) * 81.57vw);
}

.updateOptions {
  height: calc(var(--aspect-ratio) * 5vw);
}

/* .layout2Bgd {
  height: calc(var(--aspect-ratio) * 80vw);
  background-image: url("../assets/images/pizza-dark.png");
} */

.productScroll2 {
  height: calc(var(--aspect-ratio) * 54vw) !important;
}

.productCategoryScroll {
  height: calc(var(--aspect-ratio) * 14vw) !important;
}

.addOnSelectionScroll {
  height: calc(var(--aspect-ratio) * 24vw) !important;
}

.productCartScroll {
  height: calc(var(--aspect-ratio) * 40vw) !important;
}

.dineBtn,
.dineBtn:hover,
.dineBtn:focus {
  border: none;
  box-shadow: 0px 3px 6px #00000029;
}

.takeawayBtn,
.takeawayBtn:hover,
.takeawayBtn:focus {
  border: none;
  box-shadow: 0px 3px 6px #00000029;
}

.addOnSelectionModal {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #dfdfdf;
  opacity: 1;
}

.addButton,
.addButton:hover,
.addButton:focus {
  background: #4c4c4c;
  border: none;
  box-shadow: none;
}

.cancelButton,
.cancelButton:hover,
.cancelButton:focus {
  background: #848484;
  border: none;
  box-shadow: none;
}

/* Tables Management */

.tablesScroll {
  height: calc(var(--aspect-ratio) * 68.5vw) !important;
}

.reservedTableScroll {
  height: calc(var(--aspect-ratio) * 67vw) !important;
}

.reservedTableRow:nth-child(even) {
  background: #f3f1f1;
}

.selectedItemsScroll {
  height: calc(var(--aspect-ratio) * 40vw) !important;
}

.addOnsModalScroll {
  height: calc(var(--aspect-ratio) * 32vw) !important;
}

.orderItemsScroll {
  height: calc(var(--aspect-ratio) * 16vw) !important;
}

.orderCardsScroll {
  height: calc(var(--aspect-ratio) * 75vw) !important;
}

.barcodeSelectedItemsScroll {
  height: calc(var(--aspect-ratio) * 16vw) !important;
}

.center {
  text-align: center;
}

.orderIdInput,
.orderIdInput:hover,
.orderIdInput:focus {
  background: #f5f5f7;
  border: 1px solid #f5f5f7 !important;
  outline: none !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}

/* .orderIdInput::placeholder {
  font-size: 12px !important;
  font-family: "Poppins" !important;
  font-weight: normal !important;
  color: #000000 !important;
} */

.stockProdSearchIpt,
.stockProdSearchIpt:hover,
.stockProdSearchIpt:focus {
  /* background: #E2E8EC; */
  border: 1px solid #dfdfdf !important;
  border-radius: 3px;
  box-shadow: none;
  opacity: 1;
}

.stockOrderCard {
  height: calc(var(--aspect-ratio) * 79vw) !important;
}

.omsOrderCardsScroll {
  height: calc(var(--aspect-ratio) * 64vw) !important;
}

.stockOrdersScroll {
  height: calc(var(--aspect-ratio) * 52vw) !important;
}

.addProdStockOrdersScroll {
  height: calc(var(--aspect-ratio) * 45vw) !important;
}

.topSlowProdsScroll {
  height: calc(var(--aspect-ratio) * 32vw) !important;
}

.orderDetailsTableScroll {
  height: calc(var(--aspect-ratio) * 34vw) !important;
}

.addProductCodeInput:hover,
.addProductCodeInput:focus {
  border-color: #d9d9d9;
}

.customerSearchInput:hover,
.customerSearchInput:focus {
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus,
.add-payment-btn-white:hover {
  border-color: #ffa500 !important;
  color: #ffa500 !important;
}

.purchaseGrnScroll {
  height: calc(var(--aspect-ratio) * 48vw) !important;
}

.supplierSelect:hover,
.supplierSelect:focus {
  border-radius: 5px;
  box-shadow: none;
  opacity: 1;
}

.skuInput:hover,
.skuInput:focus {
  border: 1px solid #ebeded !important;
  border-radius: 5px;
  box-shadow: none;
  opacity: 1;
}

.notificationCnfmBtn,
.notificationCnfmBtn:hover,
.notificationCnfmBtn:focus {
  box-shadow: none;
  background-color: #20b883;
  color: #ffffff;
  border: 1px solid #20b883;
}

.addWastageProdScroll {
  height: calc(var(--aspect-ratio) * 50vw) !important;
}

/* New OrderType and Customer Selection Changes */

.orderTypeSelectionCard {
  background-color: #607d8b;
  border: 1px solid #607d8b;
  cursor: pointer;
}

.labelB {
  color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0.32px;
}

.addCustomerText {
  margin-bottom: 0px;
  color: #000000;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0px;
}

.salesRepCard {
  background-color: #e2e8ec;
  border-radius: 5px;
  cursor: pointer;
}

.saleRepName {
  margin-bottom: 0px;
  color: #000000;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0px;
}

.addCustIcon {
  width: calc(var(--aspect-ratio) * 3vw);
  height: calc(var(--aspect-ratio) * 3vw);
}

.salesRepoIcon {
  width: calc(var(--aspect-ratio) * 2vw);
  height: calc(var(--aspect-ratio) * 2vw);
  vertical-align: sub;
}

.salesRepClose {
  font-size: 10px;
  vertical-align: baseline;
}

.salesRepresentativeCard {
  width: 100%;
  background: #ebeded;
  border: 1px solid #ebeded;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  cursor: pointer;
}

.representativeName {
  margin-bottom: 0px;
  color: #000000;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0px;
  text-align: center;
}

.addCustomerTextRest {
  margin-bottom: 0px;
  color: #51ad9a;
  font-family: Poppins;
  font-size: 10px;
  letter-spacing: 0px;
}

.optionsBtnsfb {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.customerCardScroll {
  height: calc(var(--aspect-ratio) * 45vw) !important;
}

.tableRowSelectedBlue {
  background-color: #e6f7ff;
}

tr.tableRowSelectedBlue {
  background-color: #e6f7ff !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: rgba(230, 247, 255, 0.3) !important;
}

.tableRowNonSelectedWhite {
  background-color: #ffffff;
}

/* Bill Management Screen in F&B Pos */

.managementMainDiv {
  /* height: 100vh; */
  /* padding: 30px 40px; */
  /* padding: 10px 30px 20px; */
  padding: 20px 30px 90px;
  background-color: #f5f5f5;
}

/* .mngBtns {
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #51AD9A;
  border: 1px solid #51AD9A;
  background-color: red;
} */

.mngBtns,
.mngBtns:hover,
.mngBtns:focus {
  height: 60%;
  /* width: 70%; */
  width: 100%;
  padding: 0px 4%;
  margin-bottom: 10px;
  border: 1px solid #51ad9a;
  background-color: #ddebe8;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
}

.mngBtnText {
  font-family: Poppins;
  /* font-size: 12px; */
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.28px;
  color: #51ad9a;
  white-space: normal;
  /* overflow: hidden; */
}

.mngBtnIcon {
  /* width: 13%;
  padding-left: 10px; */
  /* width: 15%; */
  padding-left: 10px;
  vertical-align: text-bottom;
}

.enter-receipt {
  margin-bottom: 5px;
  font-family: Poppins;
  font-size: 20px;
  letter-spacing: 0px;
  color: #51ad9a;
}

.zeroBtn {
  height: calc(var(--aspect-ratio) * 10.5vw) !important;
}

.search-results {
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #b9b9b9;
}

.receipt-num {
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #000000;
  cursor: pointer;
}

.refundModalTitle {
  font-size: 14px;
  font-family: Poppins;
  letter-spacing: 0.28px;
  color: #51ad9a;
  text-align: center;
}

.refundBtns {
  padding-bottom: 10px;
  justify-content: center;
}

.refundNoBtn,
.refundNoBtn:hover,
.refundNoBtn:focus {
  margin-left: 20px;
  width: 20%;
  color: #000000;
  border-radius: 3px;
  background: #e5e5e5;
  border: none;
}

.refundYesBtn,
.refundYesBtn:hover,
.refundYesBtn:focus {
  width: 20%;
  color: #000000;
  border-radius: 3px;
  background: #e5e5e5;
  border: none;
}

.refund-remarks {
  margin-bottom: 30px;
  padding: 10px;
  border: 1px solid #ebeded;
  border-radius: 5px;
  resize: none;
}

.refund-remarks::placeholder {
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #b9b9b9;
}

.customerSelect,
.customerSelect:hover,
.customerSelect:focus {
  border: 1px solid #ebeded !important;
  border-radius: 5px;
  box-shadow: none;
  opacity: 1;
}

.focusDashboardCard:focus-visible,
.focusDashboardCard:focus {
  outline: #0d4da9 auto 1px !important;
}

.focusButtonsTill:focus-visible,
.focusButtonsTill:focus {
  outline: black auto 4px !important;
}

.prodCardNoImage {
  height: 100%;
  background: #ebeded;
  border-radius: 5px;
  border: 1px solid #ebeded;
}

.productPriceNoImg {
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 0.9vw;
  letter-spacing: 0.26px;
  color: #666666;
}

.prodNameNoImg {
  margin-bottom: 0px;
  font-size: 1.1vw;
  color: #000000;
  font-weight: normal;
  letter-spacing: 0.28px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

/* Keyboard Shortcuts Modal Starts */

.shortcutsModalTitle {
  margin-bottom: 0px;
  font-size: 18px !important;
  font-weight: normal !important;
  font-family: Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.screenTitle {
  margin-bottom: 5px;
  font-size: 14px;
  font-family: Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.shortcutsScroll {
  height: 72vh !important;
}

.shortcutUl {
  padding-left: 0px;
  list-style-type: none;
}

.shortcutList {
  margin-bottom: 10px;
}

.shortcutBtn,
.shortcutBtn:hover,
.shortcutBtn:focus {
  font-size: 12px;
  border: 1px solid #00000029;
  box-shadow: 0px 3px 0px #00000029;
  border-radius: 5px;
  color: #000000;
  text-align: center;
}

.shortcutForScrenName {
  font-size: 12px;
  font-family: Poppins;
  letter-spacing: 0px;
  color: #000000;
}

/* Keyboard Shortcuts Modal Ends */

.importBtn,
.importBtn:hover,
.importBtn:focus {
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%) !important;
}

.priceUpdateScroll {
  height: calc(var(--aspect-ratio) * 51vw) !important;
}

.add-batch-card {
  height: 66vh;
  box-shadow: 0px 2px 4px 3px #f0f0f0;
  border-radius: 8px;
  border: 1px solid #ebeded;
}

.add-batches-scroll {
  height: calc(var(--aspect-ratio) * 45vw) !important;
}

.add-batch,
.add-batch:hover,
.add-batch:focus {
  font-size: 14px;
  font-family: Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  background: #607d8b;
  border: 1px solid #607d8b;
  border-radius: 5px;
}

.add-batch-disable,
.add-batch-disable:hover,
.add-batch-disable:focus {
  font-size: 14px;
  font-family: Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  background: #bfcad0;
  border: 1px solid #bfcad0;
  border-radius: 5px;
}

.add-plus {
  font-size: 14px;
  font-family: Poppins;
  letter-spacing: 0px;
  color: #0c4da9;
  cursor: pointer;
}

.add-batch-details-input,
.add-batch-details-input:hover,
.add-batch-details-input:focus {
  width: 70%;
  padding: 5px 10px;
  background-color: #f7f7f7;
  font-weight: normal;
  text-align: center;
  border: 1px solid #ebeded;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
}

.submitBtn,
.submitBtn:hover,
.submitBtn:focus {
  width: 100%;
  background: #20b883;
  border: 1px solid #20b883;
  border-radius: 5px;
  font-size: 14px;
  font-family: Poppins;
  letter-spacing: 0.32px;
  color: #ffffff;
}

.cancelBtn,
.cancelBtn:hover,
.cancelBtn:focus {
  width: 100%;
  background: #e5e5e5;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  font-size: 14px;
  font-family: Poppins;
  letter-spacing: 0.32px;
  color: #000000;
}

.parkedBillBtn {
  padding: 11px 24px;
  background-color: #ddebe8;
  border: 1px solid #51ad9a;
  border-radius: 6px;
}

/* Responsive Changes */

.header {
  padding: 0 0 0.3%;
  background-color: #0c4da9;
}

.posMenuIcon {
  width: 27%;
}

.posBunit {
  font-weight: normal;
  font-size: 1.2vw;
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 1;
}

.timeFmt {
  margin-left: 2%;
  font-weight: normal;
  font-size: 1.2vw;
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 1;
}

.time {
  color: #ffffff;
  font-weight: normal;
  font-size: 1.2vw;
  letter-spacing: 0.36px;
  opacity: 1;
}

.posLogoImg {
  width: 60%;
}

.tickIcon {
  width: 40px;
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .main {
    height: 100vh;
  }

  .leftCard,
  .rightCard {
    height: 94vh;
  }

  .header {
    padding: 0 0 0.5%;
    background-color: #0c4da9;
  }

  .posMenuIcon {
    width: 30%;
  }

  .posBunit {
    font-size: 1.6vw;
  }

  .timeFmt {
    font-size: 1.6vw;
  }

  .time {
    font-size: 1.6vw;
  }

  .posLogoImg {
    width: 80%;
  }

  /* .leftCard,
  .rightCard {
    height: calc(var(--aspect-ratio) * 79vw);
  } */

  .addCustomerText {
    font-size: 13px;
  }

  .table-stl {
    /* height: calc(var(--aspect-ratio) * 60vw); */
    height: 79vh;
  }

  .productScroll {
    height: 82vh !important;
  }

  .productsFilterBtn {
    padding: 0px;
  }

  .qty-card {
    height: 10vh;
    padding-top: 10px;
  }

  .prodQtyCard {
    padding-top: 50px !important;
    height: 50vh;
  }

  .offersCard {
    height: 50vh;
  }

  .infoCardList {
    height: 42vh !important;
  }

  .downIcon {
    height: 3vh;
    width: 3vh;
  }

  .taxDisLbl {
    font-size: 13px !important;
  }

  .totalBtn {
    height: 6vh;
  }

  .totalLabel {
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  .backArrowSearchProducts {
    font-size: 24px !important;
  }

  .productName {
    font-size: 13px !important;
  }

  .add-product-save,
  .add-product-pay {
    height: 7vh;
    font-size: 19px !important;
  }

  .skuUpcText {
    font-size: 12px !important;
  }

  .productDetails {
    font-size: 18px !important;
  }

  .prodNameInfo, .priceNameInfo {
    font-size: 14px !important;
  }

  .prod-desc-lbl {
    font-size: 13px !important;
  }

  .productInfoCard {
    height: 20vh;
  }

  .qtyBtnClose {
    width: 15px !important;
    height: 15px !important;
  }

  .backSpaceIcon {
    width: 25px !important;
    height: 16px !important;
  }

  .tickIcon {
    width: 20px;
  }

  .productInfoTabsHeading {
    font-size: 18px !important;
  }

  .qtySales {
    font-size: 14px !important;
  }

  .qtySalesValue {
    font-size: 18px !important;
  }

  .stockTitles {
    font-size: 13px !important;
  }

  .salesPercentValues {
    font-size: 14px !important;
  }

  .prodDescInfo {
    font-size: 14px !important;
  }
}

